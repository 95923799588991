<template>
  <div class="disclosure">
    <h1>Electrify America Pass for My BMW</h1>
    <h2>Charging Plan Terms and Conditions</h2>
    <p class="primary-color"><strong>Effective as of March 2025</strong></p>
    <p>
      Electrify America and BMW are pleased to provide this charging plan to drivers of BMW electric vehicles using the
      My BMW app. By clicking “Agree” below, you agree to participate in this plan, subject to your compliance with
      these terms and conditions.
    </p>

    <p>
      Participation requires the My BMW app, enrollment in the Electrify America Pass for My BMW charging plan, and
      acceptance of Electrify America’s Terms of Use and the end user license agreement or other terms of use that
      govern your use of the My BMW app. Your mobile carrier’s messaging, data, and other rates and fees will apply to
      your use of the My BMW app. You are required to use a credit or debit card to pay for all applicable charging
      fees, session fees, idle fees, taxes or other under this plan. See the Electrify America Terms of Use available at
      <router-link class="link" :to="{ name: 'terms-en' }">https://www.electrifyamerica.com/terms</router-link> for
      further details.
    </p>
    <p>
      Immediately upon your registration and acceptance of these terms, you will be enrolled in a plan that delivers
      charging services at the prevailing Electrify America Pass pricing at your charger, on Electrify America’s network
      of electric vehicle charging stations including participating Commercial Fast Charging stations.
    </p>
    <p>
      After signing up, you can initiate a charging session at any Electrify America charging station and participating
      Commercial Fast Charging stations, by using the My BMW app “Swipe to start” feature with the appropriate plan
      selected. For capable vehicles, Plug&Charge functionality will also be available for use with this plan.
      Plug&Charge can help owners of capable BMW models have a better charging experience by creating a secure,
      convenient, and effortless way to pay for charging sessions on the Electrify America network.
    </p>
    <p class="primary-color">
      <strong>PLEASE NOTE THE FOLLOWING IMPORTANT LIMITATIONS ON YOUR USE OF THIS PLAN:</strong>
    </p>
    <p>
      You may <strong>only</strong> use this charging plan for charging the BMW model associated with your My BMW
      account. <strong>No other vehicle is eligible to use this plan through your account,</strong> whether owned by you
      or others.
    </p>
    <p>
      This plan <strong>does not include</strong> any complimentary charging, idle fees, session fees, and their
      associated applicable taxes assessed at the charging station. You will be responsible for paying such fees through
      your account if you incur them during a charging session. At the beginning of each charging session, Electrify
      America will pre-authorize your account for $20 dollars and in additional increments of $20 dollars to cover these
      potential charges if they apply.
    </p>
    <p>
      You must follow all product, vehicle, safety, and technical documentation included with the BMW vehicle model when
      charging at an Electrify America charging station.
    </p>
    <p>
      You must use the correct connector type and charge at the correct power level for the BMW vehicle model at the
      charging station.
    </p>
    <p>
      You acknowledge that your use of Electrify America’s services is subject to the Electrify America Terms of Use
      available at
      <router-link class="link" :to="{ name: 'terms-en' }">https://www.electrifyamerica.com/terms</router-link> and that
      your use of the app is of subject to the Terms of Use accessible by navigating to your Electrify America account,
      selecting <strong>“Legal”</strong> and then selecting <strong>“Terms of Use” & “Privacy Policy.”</strong> Please
      read these Terms of Use documents carefully for information about how you can pay through your account for
      charging fees, session fees, idle fees and applicable taxes.
    </p>
    <p>
      You agree to comply with all applicable laws and regulations when using Electrify America services and when
      charging your vehicle at Electrify America charging stations.
    </p>
    <p>
      Electrify America reserves the right to withhold, revoke, reduce, terminate, or suspend your access to all or any
      portion of this plan, without notice, if Electrify America determines or suspects, in its sole discretion, that
      you: (a) are in violation of the Electrify America Terms of Use, these Terms and Conditions, the My BMW end user
      license agreement or other terms of use, or any other contract between you and Electrify America or BMW; (b) have
      engaged in charging sessions that are excessive, fraudulent, or otherwise indicate an unauthorized or unintended
      use of this plan; or (c) have engaged in any illegal, fraudulent, tortious, injurious, harmful, or abusive conduct
      in your use of the Electrify America services, the My BMW app, or an Electrify America charging station.
    </p>
    <p>
      Electrify America reserves the right to end access to this plan at any time for any reason, in its sole
      discretion, provided that Electrify America will send an email to the address associated with your account in the
      event of any change to the availability of this plan. Following the termination or expiration of this plan for any
      reason, your access to the plan in the My BMW app will immediately cease.
    </p>
  </div>
</template>

<script>
export default {
  name: 'pass-for-mybmw-plan-disclosure',
  metaInfo: {
    title: 'Pass for My BMW | Electrify America',
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/pass-for-mybmw-plan-disclosure/' }],
  },
};
</script>
